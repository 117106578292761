import { Box, Heading, Text, Flex } from '@chakra-ui/layout';
import { ArrowForwardIcon, PhoneIcon } from '@chakra-ui/icons';
import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import { FaFax, FaPhoneAlt } from 'react-icons/fa';
import { GrMail } from 'react-icons/gr';
import { LinkOne, LinkPhone, LinkEmail } from './index';
import { formatePhone } from '../helpers/index';

const Profile = ({ profile }) => {
  const formatedPhone = formatePhone(profile.node.frontmatter.phone);
  const formatedFax = formatePhone(profile.node.frontmatter.fax);

  return (
    <Flex mx={10} my={16} grow="1">
      <Box position="relative" my={10}>
        <Box
          style={{ background: '#546A5C' }}
          bg="brand.flare"
          h="200px"
          w="100px"
          pos="absolute"
          top="-40px"
          left="-40px"
          p={3}
        />
        <Img
          fixed={
            profile.node.frontmatter.profileImg.childrenImageSharp[0].fixed
          }
        />
      </Box>
      <Flex ml={10} flexDirection="column" justifyContent="space-between">
        <Heading
          height="90px"
          as="h3"
          size="xl"
          textTransform="uppercase"
          mb={3}
        >
          {profile.node.frontmatter.title}
        </Heading>
        <Heading as="h4" size="md" mb={3}>
          {profile.node.frontmatter.name}
        </Heading>

        {profile.node.frontmatter.email ? (
          <Text as="h4" size="sm" mb={3}>
            <Flex
              alignItems="center"
              width="220px"
              justifyContent="space-between"
            >
              <GrMail /> <LinkEmail>{profile.node.frontmatter.email}</LinkEmail>
            </Flex>
          </Text>
        ) : (
          <div />
        )}
        {profile.node.frontmatter.phone ? (
          <Text as="h4" size="sm" mb={3}>
            <Flex
              alignItems="center"
              width="220px"
              justifyContent="space-between"
            >
              <FaPhoneAlt /> <LinkPhone>{formatedPhone}</LinkPhone>
            </Flex>
          </Text>
        ) : (
          <div />
        )}

        {profile.node.frontmatter.fax ? (
          <Text as="h4" size="sm" mb={3}>
            <Flex
              alignItems="center"
              width="220px"
              justifyContent="space-between"
            >
              <FaFax /> <LinkPhone>{formatedFax}</LinkPhone>
            </Flex>
          </Text>
        ) : (
          <div />
        )}

        <LinkOne to={profile.node.fields.path}>Professional Bio</LinkOne>
      </Flex>
    </Flex>
  );
};

export default Profile;
