import React from 'react';
import { Box } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { SEO, Sidenav, Footer } from './index';
import '../assets/sass/index.scss';

const Layout = ({ children }) => {
  return (
    <>
      <SEO />
      <Sidenav />
      <Box minHeight="100vh" overflow="hidden">
        <Box pt={20}>{children}</Box>
      </Box>
      <Footer />
    </>
  );
};

export default Layout;
