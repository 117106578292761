import { Flex, Box, Container, Heading, Text } from '@chakra-ui/layout';
import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import PropTypes from 'prop-types';
import { FaFax, FaPhoneAlt } from 'react-icons/fa';
import Logo from '../assets/ccg-mark.svg';
import { LinkEmail, LinkPhone } from './index';

const Footer = () => {
  const [connect, setConnect] = useState('');
  const { site } = useStaticQuery(query);
  const { phone, fax, email } = site.siteMetadata;
  return (
    <>
      <Box bg="brand.dark" color="white">
        <Container maxW="container.xl" h={96} py={6}>
          <Flex h="100%" flexDirection="column" justifyContent="space-between">
            <Box>
              <Flex alignItems="center" mb={4}>
                <Logo
                  height="auto"
                  width={60}
                  color="white"
                  stroke="white"
                  fill="white"
                  bg="white"
                />
                <Heading
                  as="h6"
                  size="sm"
                  fontWeight="light"
                  textTransform="uppercase"
                  ml={4}
                >
                  Chancellor Consulting Group
                </Heading>
              </Flex>
              <Heading as="h4" size="sm" mb={3}>
                <LinkEmail>kdrosihn@chancellorconsulting.com</LinkEmail>
              </Heading>

              <Heading as="h4" size="sm" mb={3}>
                749 Atherton Avenue, Novato, CA 94945
              </Heading>
              <Heading as="h4" size="sm" mb={3} />
              <Heading as="h4" size="sm" width="220px">
                <Flex
                  width="220px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FaPhoneAlt /> <LinkPhone>{phone}</LinkPhone>
                </Flex>
              </Heading>
              <Heading as="h4" size="sm" mb={4}>
                <Flex
                  width="220px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <FaFax /> <LinkPhone>{fax}</LinkPhone>
                </Flex>
              </Heading>
            </Box>

            <Box py={4}>
              <Text>Copyright CCG 2021</Text>
              <Link to="/privacy">
                <Text>Privacy</Text>
              </Link>
            </Box>
          </Flex>
        </Container>
      </Box>
      {/* <ConnectModal item={connect} /> */}
    </>
  );
};

Footer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  phone: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
};

Footer.defaultProps = {
  title: null,
  description: null,
  image: null,
  phone: null,
  fax: null,
  email: null,
};

const query = graphql`
  query Footer {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        phone
        fax
        email
      }
    }
  }
`;

export default Footer;
