import React, { Profiler, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GrMail } from 'react-icons/gr';
import { HamburgerIcon } from '@chakra-ui/icons';
import { FaFax, FaPhoneAlt } from 'react-icons/fa';
import {
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Divider,
  Flex,
  Box,
  Spacer,
  Heading,
  color,
} from '@chakra-ui/react';
import Logo from '../assets/ccg-mark.svg';
import { LinkPhone, LinkEmail } from './index';

const menu = [
  {
    name: 'Home',
    link: '/',
  },
  {
    name: 'Services & Client Tools',
    link: '/services',
  },
  // {
  //   name: 'Client Tools',
  //   link: '/client-tools',
  // },
  {
    name: 'Professionals',
    link: '/professionals',
  },
  // {
  //   name: 'Recent Clients',
  //   link: '/recent-clients',
  // },
];

const Sidenav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { site } = useStaticQuery(query);

  const { phone, fax, email } = site.siteMetadata;

  const btnRef = useRef();
  return (
    <>
      <Flex
        bg="brand.dark"
        color="white"
        position="fixed"
        top="0px"
        width="100%"
        p={3}
        zIndex="10"
      >
        <Link to="/">
          <Flex alignItems="center">
            <Logo
              height="auto"
              width={60}
              color="white"
              stroke="white"
              fill="white"
              bg="white"
            />
            <Box ml={3}>
              <Heading as="h4" size="sm" textTransform="uppercase">
                Chancellor Consulting Group
              </Heading>
              <Heading as="h4" size="xs" textTransform="uppercase">
                Established 1991
              </Heading>
            </Box>
          </Flex>
        </Link>
        <Spacer />
        <Box>
          <Button ref={btnRef} variant="link" onClick={onOpen} mt={4}>
            <HamburgerIcon h="auto" w={8} />
          </Button>
        </Box>
      </Flex>

      <Drawer
        isOpen={isOpen}
        placement="right"
        size="md"
        onClose={onClose}
        finalFocusRef={btnRef}
        // colorScheme="teal"
        bg="teal"
      >
        <DrawerOverlay>
          <DrawerContent bg="brand.dark" color="white" pt={3}>
            <DrawerCloseButton mt={6} />

            <DrawerHeader>
              <Link to="/" onClick={onClose}>
                <Flex alignItems="center" justifyContent="flex-start">
                  <Logo fill="white" width={50} height="auto" />
                  <Heading ml={4} as="h4" size="md" textTransform="uppercase">
                    Chancellor Consulting Group
                  </Heading>
                </Flex>
              </Link>
            </DrawerHeader>

            <DrawerBody>
              <Flex flexDirection="column" justifyContent="start">
                {menu.map((m, i) => (
                  <Button
                    justifyContent="flex-start"
                    key={i}
                    variant="link"
                    my={3}
                  >
                    <Link to={m.link} onClick={onClose}>
                      <Heading as="h4" size="lg" color="white">
                        {m.name}
                      </Heading>
                    </Link>
                  </Button>
                ))}

                <Divider my={6} />

                <Heading as="h4" size={{ sm: 'md', md: 'lg' }} mb={4}>
                  Contact
                </Heading>

                <Heading as="h4" size="sm" mb={4}>
                  <Flex
                    alignItems="center"
                    width="220px"
                    justifyContent="space-between"
                  >
                    <FaPhoneAlt /> <LinkPhone>{phone}</LinkPhone>
                  </Flex>
                </Heading>
                <Heading as="h4" size="sm" mb={4}>
                  <Flex
                    width="220px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <FaFax /> <LinkPhone>{fax}</LinkPhone>
                  </Flex>
                </Heading>
                <Heading as="h4" size="sm" mb={4}>
                  <Flex
                    width="340px"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <GrMail /> <LinkEmail>{email}</LinkEmail>
                  </Flex>
                </Heading>
              </Flex>
            </DrawerBody>

            <DrawerFooter />
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
};

Sidenav.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
  phone: PropTypes.string,
  fax: PropTypes.string,
  email: PropTypes.string,
};

Sidenav.defaultProps = {
  title: null,
  description: null,
  image: null,
  phone: null,
  fax: null,
  email: null,
};

const query = graphql`
  query Sidenav {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        phone
        fax
        email
      }
    }
  }
`;

export default Sidenav;
